var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tTemplate",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tTemplateForm",
          attrs: {
            model: _vm.tTemplateForm,
            rules: _vm.tTemplateFormRule,
            "label-width": 110,
          },
        },
        [
          _c(
            "FormItem",
            { attrs: { label: "报告类型", prop: "reportType" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择",
                    clearable: "",
                    transfer: "",
                  },
                  model: {
                    value: _vm.tTemplateForm.reportType,
                    callback: function ($$v) {
                      _vm.$set(_vm.tTemplateForm, "reportType", $$v)
                    },
                    expression: "tTemplateForm.reportType",
                  },
                },
                _vm._l(_vm.reportTypePriority, function (item, i) {
                  return _c(
                    "Option",
                    { key: i, attrs: { value: item.value } },
                    [_vm._v(" " + _vm._s(item.title) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "模板类型", prop: "type" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择",
                    clearable: "",
                    transfer: "",
                  },
                  model: {
                    value: _vm.tTemplateForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.tTemplateForm, "type", $$v)
                    },
                    expression: "tTemplateForm.type",
                  },
                },
                _vm._l(_vm.templateTypePriority, function (item, i) {
                  return _c(
                    "Option",
                    { key: i, attrs: { value: item.value } },
                    [_vm._v(" " + _vm._s(item.title) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.tTemplateForm.type == "分项报告"
            ? _c(
                "FormItem",
                { attrs: { label: "科室名", prop: "tOfficeId" } },
                [
                  _c(
                    "Select",
                    {
                      attrs: {
                        placeholder: "请选择组合项目",
                        filterable: "",
                        disabled: _vm.disabled,
                      },
                      on: { "on-change": _vm.queryAllPortfolioProjectData },
                      model: {
                        value: _vm.tTemplateForm.tOfficeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.tTemplateForm, "tOfficeId", $$v)
                        },
                        expression: "tTemplateForm.tOfficeId",
                      },
                    },
                    _vm._l(_vm.officeData, function (item, i) {
                      return _c(
                        "Option",
                        { key: i, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.sectionName))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.tTemplateForm.type == "分项报告"
            ? _c(
                "FormItem",
                { attrs: { label: "组合项目名称", prop: "baseProjectId" } },
                [
                  _c(
                    "Select",
                    {
                      attrs: {
                        placeholder: "请选择组合项目",
                        filterable: "",
                        disabled: _vm.disabled || _vm.baseProjectIdDisabled,
                      },
                      model: {
                        value: _vm.tTemplateForm.baseProjectId,
                        callback: function ($$v) {
                          _vm.$set(_vm.tTemplateForm, "baseProjectId", $$v)
                        },
                        expression: "tTemplateForm.baseProjectId",
                      },
                    },
                    _vm._l(_vm.treeData, function (item, i) {
                      return _c(
                        "Option",
                        { key: i, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "FormItem",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "RadioGroup",
                {
                  model: {
                    value: _vm.tTemplateForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.tTemplateForm, "status", $$v)
                    },
                    expression: "tTemplateForm.status",
                  },
                },
                _vm._l(_vm.statusRadio, function (item) {
                  return _c(
                    "Radio",
                    {
                      key: item.value,
                      attrs: { disabled: _vm.disabled, label: item.value },
                    },
                    [_vm._v(_vm._s(item.title))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "模板内容", prop: "contentName" } },
            [
              _c(
                "Upload",
                {
                  ref: "up",
                  attrs: {
                    action: _vm.deployByFileUrl,
                    headers: _vm.accessToken,
                    disabled: _vm.disabled,
                    "on-success": _vm.handleSuccess,
                    "on-error": _vm.handleError,
                    format: ["ftl", "docx"],
                    "max-size": 5120,
                    "on-format-error": _vm.handleFormatError,
                    "on-exceeded-size": _vm.handleMaxSize,
                    "before-upload": _vm.handleBeforeUpload,
                    type: "drag",
                  },
                },
                [
                  _vm.fileName == null || _vm.fileName.trim().length == 0
                    ? _c(
                        "div",
                        { staticStyle: { padding: "20px 0" } },
                        [
                          _c("Icon", {
                            staticStyle: { color: "#3399ff" },
                            attrs: { type: "ios-cloud-upload", size: "52" },
                          }),
                          _c("p", [_vm._v("点击这里或将文件拖拽到这里上传")]),
                          _vm._v(" 仅支持docx、ftl格式文件 "),
                        ],
                        1
                      )
                    : _c("div", { staticStyle: { padding: "20px 0" } }, [
                        _vm._v(" " + _vm._s(_vm.fileName) + " "),
                      ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "排序", prop: "orderNum" } },
            [
              _c("InputNumber", {
                attrs: { disabled: _vm.disabled, max: 10, min: 1, step: 1 },
                model: {
                  value: _vm.tTemplateForm.orderNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.tTemplateForm, "orderNum", $$v)
                  },
                  expression: "tTemplateForm.orderNum",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }